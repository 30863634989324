import './home.scss';
import dataApi from '@/api/manage/exchange';

const colorList = ['#f56a00', '#7265e6', '#ffbf00', '#00a2ae'];
export default {
  name: 'index',
  data() {
    return {
      color: colorList[0],
      userInfo: {},
      form: {
        imgUrl3: '',
        imgUrl4: ''
      },
      energyOP: {
        span: 12,
        data: [
          {
            count: 0,
            decimals: 0,
            title: '今日消耗能量值',
            router: '/member/energy',
            click: (item) => {
              this.toPage(item);
            }
          },
          {
            count: 0,
            decimals: 0,
            title: '今日充值能量值',
            router: '/member/recharge',
            click: (item) => {
              this.toPage(item);
            }
          }
        ]
      },
      memberOP: {
        span: 12,
        data: [
          {
            count: 0,
            decimals: 0,
            title: '新增会员数',
            router: '/member/list',
            click: (item) => {
              this.toPage(item);
            }
          },
          {
            count: 0,
            decimals: 0,
            title: '累计会员数',
            router: '/member/list',
            click: (item) => {
              this.toPage(item);
            }
          }
        ]
      },
      activityOP: {
        span: 12,
        data: [
          {
            count: 0,
            decimals: 0,
            title: '今日兑换数',
            router: '/manage/exchange',
            click: (item) => {
              this.toPage(item);
            }
          },
          {
            count: 0,
            decimals: 0,
            title: '今日核销数',
            router: '/operating/have_class',
            click: (item) => {
              this.toPage(item);
            }
          }
        ]
      }
    };
  },
  created() {
    // this.userInfo = this.$store.getters.userInfo;
    dataApi.getTotal.r().then(res => {
      console.log(res);
      this.memberOP.data[0].count = res.data.member.add_new_count;
      this.memberOP.data[1].count = res.data.member.total;
      this.energyOP.data[0].count = res.data.energy.norm_sum;
      this.energyOP.data[1].count = res.data.energy.top_sum;
      this.activityOP.data[0].count = res.data.energy.today_count;
      this.activityOP.data[1].count = res.data.energy.today_check_count;
    });
  },
  mounted() {},
  computed: {
    isLoading() {
      const arr = Object.keys(this.$store.getters.userInfo);
      console.log('arr', arr);
      if (arr.length > 0) {
        this.userInfo = this.$store.getters.userInfo;
        return false;
      }
    }
  },
  watch: {
    isLoading: {
      handler(val) {
        if (val) {
          // 已获取
          console.log('start...', val);
        }
      }
    }
  },
  methods: {
    toPage(item) {
      console.log(item);
      this.$router.push(item.router);
    }
  }
};
