<template>
  <div>
    <el-card class="user-panel" shadow="never" :body-style="{ width: '100%' }">
      <el-row class="home-page" :gutter="20" type="flex">
        <el-col :span="18" class="info">
          <div class="avatar">
            <!--            <el-avatar :size="80" :src="userInfo.avatar"></el-avatar>-->
            <avue-avatar
              :size="80"
              :style="{ backgroundColor: color, verticalAlign: 'middle' }"
            >
              {{ userInfo.nick_name }}</avue-avatar
            >
          </div>
          <div class="text-box">
            <div class="tip"> {{ userInfo.tip }} </div>
            <div
              >{{ userInfo.role_name }} | 上次登录时间:
              {{ userInfo.login_date }} | 上次登录IP:{{
                userInfo.login_ip
              }}</div
            >
          </div>
        </el-col>
        <el-col :span="6" class="extra"> </el-col>
      </el-row>
      <el-row>
        <el-col> </el-col>
      </el-row>
    </el-card>
    <el-row :gutter="12">
      <el-col :span="8">
        <el-card shadow="never">
          <div slot="header" class="clearfix">
            <span>会员数</span>
          </div>
          <avue-data-display :option="memberOP"></avue-data-display>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card shadow="never">
          <div slot="header" class="clearfix">
            <span>能量值</span>
          </div>
          <avue-data-display :option="energyOP"></avue-data-display>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card shadow="never">
          <div slot="header" class="clearfix">
            <span>兑换</span>
          </div>
          <avue-data-display :option="activityOP"></avue-data-display>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
<script>
// eslint-disable-next-line import/no-cycle
import Home from './home.js';

export default Home;
</script>
